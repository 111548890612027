<template>
    <div class="recommendable-reason">
        <StackRouterHeaderBar />

        <main class="main">
            <FeedbackTop :title="title" :description="'선택한 내용은 상대방에게 공개되지 않아요'" />

            <ul class="feedback-option-items" v-if="items.length > 0">
                <p v-if="multiSelect" class="multi-select m-b-8">*복수 선택 가능</p>
                <li v-for="(item, idx) in items" :key="item.id">
                    <p
                        v-html="item.title"
                        class="item"
                        :class="{ selected: item.selected }"
                        @click="onClickItem(idx)"
                    />
                    <transition name="slide-down">
                        <div v-if="showDetailReason && item.title === '직접 입력'" class="detail-reason m-b-12">
                            <p class="desc m-b-8">직접 입력해주세요</p>
                            <TextareaWithX :placeholder="'내용을 입력해주세요'" v-model="detailReason" />
                        </div>
                    </transition>
                </li>
            </ul>
            <!-- <FeedbackOptionItems v-if="items.length > 0" v-model="items" :multiSelect="true" /> -->
            <!-- <FeedbackDescription class="m-b-40" /> -->
        </main>

        <footer class="footer">
            <button class="btn btn-primary" v-html="$translate('SUBMIT')" :disabled="disabled" @click="onClickSubmit" />
        </footer>
    </div>
</template>

<script>
import FeedbackTop from './components/FeedbackTopComponent'
import datingService from '@/services/dating'

export default {
    name: 'RecommendableReasonPage',
    components: { FeedbackTop },
    props: {
        type: String,
        user: Object,
    },
    data: () => ({
        detailReason: '',
        items: [],
        multiSelect: true,
        title: '어떤 점에서 다른 분에게<br/>소개해줘도 괜찮나요?',
    }),
    computed: {
        disabled() {
            return this.items.filter(item => item.selected).length === 0
        },
        showDetailReason() {
            const etc = this.items.find(item => item.title === '직접 입력')
            if (!etc) return

            return etc.selected
        },
    },
    methods: {
        async init() {
            if (this.type === 'meet') {
                this.title = `${
                    this.user.name || this.user.nickname || this.user.profile.nickname
                }님의<br>괜찮은 점이 있다면 알려주세요!`
            }

            const options = await this.$store.dispatch(
                'filterFeedbackOptionByCategory',
                `recommend_reason${this.type === 'meet' ? '_m' : ''}`
            )

            this.items = options.map(option => {
                this.$set(option, 'selected', false)
                return option
            })
        },
        async onClickSubmit() {
            const payload = this.preparedPayload()
            this.$store.commit('setSelectedFeedbacks', { category: 'recommendReason', feedbacks: payload })
            try {
                this.$loading(true)
                const { payload, dating } = await this.$store.dispatch('prepareFeedbackPayload')
                await datingService.feedback(dating.id, payload)
                await this.$store.dispatch('loadDatingSuccess')

                this.$toast.success('THANKS_TO_SUBMIT')
            } catch (e) {
                this.$toast.error(e.data)
            } finally {
                this.$loading(false)
                this.$stackRouter.clear()
            }
        },
        preparedPayload() {
            return this.items
                .filter(item => item.selected)
                .map(item => {
                    if (item.title === '직접 입력') {
                        return { id: item.id, detail: this.detailReason }
                    } else {
                        return { id: item.id }
                    }
                })
        },
        onClickItem(idx) {
            if (!this.multiSelect) {
                this.items.forEach(item => {
                    item.selected = false
                })
                this.$set(this.items[idx], 'selected', !this.items[idx].selected)
                // setTimeout(() => this.$emit('select', idx), 500) // select 이벤트는 단일 선택인 경우에만 emit
            } else {
                this.$set(this.items[idx], 'selected', !this.items[idx].selected)
            }

            // this.$emit('input', this.items)
        },
    },
    mounted() {
        this.init()
    },
}
</script>

<style lang="scss" scoped>
.recommendable-reason {
    .main {
        height: calc(100% - 56px - 80px);
        overflow-y: auto;
        padding: 0 16px;

        .feedback-option-items {
            margin-bottom: 24px;

            .multi-select {
                font-size: 12px;
                color: $grey-07;
            }

            li {
                .item {
                    padding: 11px 16px;
                    border-radius: 8px;
                    border: solid 1px $grey-02;
                    font-size: 15px;
                    color: $grey-08;
                    min-height: 44px;

                    // &:not(:last-child) {
                    margin-bottom: 8px;
                    // }

                    &.selected {
                        border-color: $blue-primary;
                        color: black;
                    }
                }
            }
        }
    }

    .items {
        margin-bottom: 24px;

        .item {
            padding: 11px 16px;
            border-radius: 8px;
            border: solid 1px $grey-02;

            &:not(:last-child) {
                margin-bottom: 8px;
            }
        }
    }

    .detail-reason {
        .desc {
            font-size: 12px;
            color: $grey-06;
            font-weight: 500;

            @include f-medium;
        }

        .textarea-with-x {
            height: 160px;
        }
    }

    .footer {
        position: absolute;
        bottom: 0;
        padding: 16px 20px;
        width: 100vw;
        border-top: solid 1px $grey-02;

        .btn {
            width: 100%;
            height: 48px;
        }
    }
}
</style>
